import React from "react"
import PropTypes from "prop-types"
import { Grid, Box } from "@material-ui/core"
import TextFormatter, { Content } from "../TextFormatter"

import { createStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => {
  return createStyles({
    border: {
      width: "100%",
      height: "2px",
      backgroundColor: theme.palette.primary.light,
      margin: `0 ${theme.spacing(1)}px`,
      marginBottom: theme.spacing(2),
    },
  })
})

const Table = ({ content, tableName, borderBottom = true }) => {
  const classes = useStyles()
  return content.map((row, i) => (
    <Grid container spacing={2}>
      {row.map((cell, j) => (
        <Grid item sm={6}>
          <TextFormatter
            classes={{ root: classes.cell }}
            content={cell}
            keyPrefix={`${tableName}-${i}-${j}`}
          />
        </Grid>
      ))}
      {borderBottom && i < content.length - 1 && (
        <Box classes={{ root: classes.border }} />
      )}
    </Grid>
  ))
}

const Cell = PropTypes.arrayOf(Content)

const Row = PropTypes.arrayOf(Cell)

Table.propTypes = {
  content: PropTypes.arrayOf(Row),
  borderBottom: PropTypes.bool,
  tableName: PropTypes.string,
}

export default Table
