import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { CTA, CTA_NAMES } from "../components/CTAs"
import { FirstSection } from "../components/Sections"

import { Typography, Box, Grid } from "@material-ui/core"
import { BoxesSection, FocusSection } from "../components/Sections"
import { Hardware } from "../components/images"

import {
  BasicContentBox,
  ThreeColumnsContentBox,
} from "../components/ContentBoxes"

import SectionSeparator from "../components/SectionSeparator/SectionSeparator"
import SectionContainer from "../components/SectionContainer"
import { HalfCircle, FilledCircle, EmptyCircle } from "../components/Icons"
import Table from "../components/Table"

import { createStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => {
  return createStyles({
    hardwareImage: {
      position: "relative",
      width: "100vw",
      top: "-30px",
      [theme.breakpoints.up(430)]: {
        left: "10vw",
        width: "80vw",
      },
      [theme.breakpoints.up("sm")]: {
        left: "auto",
        position: "absolute",
        top: "92px",
        left: "240px",
        width: "63vw",
      },
      [theme.breakpoints.up(750)]: {
        top: "88px",
        width: "60vw",
        left: "295px",
      },
      [theme.breakpoints.up("md")]: {
        right: "-40px",
        top: "-75px",
        width: "550px",
        height: "500px",
        left: "auto",
      },
    },
    solutionsColumnsContainer: {
      "& div": { maxWidth: "350px" },
      "& div:nth-child(2)": {
        position: "relative",
        [theme.breakpoints.only("sm")]: {
          top: "200px",
        },
      },
    },
  })
})

const PRICING_CONTENT = [
  [
    [
      { type: "h4", data: "Custody Base Fee — Range" },
      { type: "paragraph", data: "30 - 100 bps" },
    ],
    [
      {
        type: "paragraph",
        data:
          "Insurance allocations are dedicated per client account with certificates issued by Marsh.",
      },
    ],
  ],
  [
    [
      { type: "h4", data: "Custody Blended Fees — Bespoke" },
      {
        type: "paragraph",
        data:
          "Adjusted on total AUC, cold storage withdrawal frequency, and programmatic access limits.",
      },
    ],
  ],
  [
    [
      { type: "h4", data: "Monthly Minimum Fee" },
      {
        type: "paragraph",
        data: "$2,500",
      },
    ],
  ],
]

const CustodyPage = () => {
  const classes = useStyles()
  return (
    <Layout>
      <SEO title="Custody" />
      <FirstSection
        h1="Custody"
        boxHeader="Simplicity and No Harm to Security"
        image="Boulder1"
      >
        <Typography variant="body1">
          Managing Bitcoin keys is a usability and security trade-off. Operating
          robust technology, we built our modular product for uncompromising
          service levels and simple integration.
        </Typography>
      </FirstSection>
      <SectionSeparator title="Custody solutions" />
      <SectionContainer>
        <Box position="relative">
          <BasicContentBox header="Accessible Custody" maxWidth="400px" mb={6}>
            <Typography variant="body1">
              Simple and secure Bitcoin custody from manual to programmatic
              access—on your own terms.
            </Typography>
          </BasicContentBox>
          <Box classes={{ root: classes.hardwareImage }}>
            <Hardware />
          </Box>
        </Box>
        <ThreeColumnsContentBox
          classes={{ root: classes.solutionsColumnsContainer }}
          boxes={[
            {
              icon: <FilledCircle />,
              header: "Terminal with Access Keys",
              paragraphs: [
                "Knox account administrators can use the terminal with personal access keys as a secure interface to their private custody account and initiate transactions to our air-gapped facilities. Web apps introduce security holes. ",
              ],
            },
            {
              icon: <EmptyCircle />,
              header: "Cold Storage API",
              paragraphs: [
                "Programmatic access is critical to our advanced users or models with high throughput like exchanges and trading desks. Bespoke integrations become a breeze. ",
              ],
            },
            {
              icon: <HalfCircle />,
              header: "Hybrid Model",
              paragraphs: [
                "Get a simple interface, secure access, and custom governance controls afforded by our Terminal and Access Keys, with the convenience of programmatic access through our Cold Storage API.",
              ],
            },
          ]}
        />
        <Box display="flex" justifyContent="center" mt={2}>
          <CTA name={CTA_NAMES.scheduleDemo} />
        </Box>
      </SectionContainer>

      <SectionSeparator title="Governance" />

      <BoxesSection
        boxes={[
          {
            align: "right",
            maxWidth: "550px",
            margin: { xs: { top: "150px" } },
            content: (
              <BasicContentBox header="Don’t Give in Control">
                <>
                  <Typography variant="body1">
                    Knox is incapable of arbitrarily moving client holdings,
                    without explicit client requests made prior. All account
                    approval quorums are bespoke, and chosen by clients with
                    specific policies including subsets of authorizers,
                    transaction thresholds, and daily rate limits—per wallet.
                  </Typography>
                  <Box mt={4}>
                    <CTA name={CTA_NAMES.security} />
                  </Box>
                </>
              </BasicContentBox>
            ),
          },
        ]}
        image={{
          name: "Boulder2",
        }}
      />
      <SectionSeparator title="Pricing" id="pricing" />
      <SectionContainer>
        <BasicContentBox
          header="Simple Pricing with Flexibility"
          maxWidth="400px"
          mb={4}
        >
          <Typography variant="body1">
            All segregated customer accounts are expected at minimum to hold $1M
            of assets under custody (AUC). All fees can be adjusted based on
            projected AUC, and monthly transaction volumes.
          </Typography>
        </BasicContentBox>
        <BasicContentBox maxWidth="600px" mb={6}>
          <Table content={PRICING_CONTENT} tableName="pricing" />
        </BasicContentBox>
      </SectionContainer>
      <FocusSection
        paragraphs={[
          "If you have any questions about our pricing, please reach out to us directly, we’d be happy to give you more details.",
        ]}
        align="center"
        cta={<CTA name={CTA_NAMES.scheduleCall} />}
      />
    </Layout>
  )
}

export default CustodyPage
